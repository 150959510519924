import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from "@mui/material/colors";
import CardLocal from "../cardLocal";
import FormSection from "../FormSection";
import Gallery from "./Slide";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import Slide from "./Slide";
import Slide1 from "./Slide1";
import ani from '../images/loggo.png';
import muz from '../video/mireasa.mp3'
import CardInvitatie from "./CardInvitatie";


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="850px" opacity=".5" >
                        <div className="row text-center align-items-center " style={{ position: 'absolute' }}>
                            <div className="col-12 mb-5"style={{ maxWidth: '600px', padding: '30px' }} >
                                <h4 style={{ fontSize: '36px', color: '' }}>{item.mire} {item.mireasa}</h4>
                                <img className="gif" src={ani} />
                                <h5 style={{ fontSize: '30px' }}>{item.savedata}<br />{item.data}</h5>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


<CardInvitatie/>
  

<Slide />

            

  

       <CardLocal />


    

        

             
            
 
          
            {data.introData.map((item) => {
                return (
                    <div>
                        <h2 className="text-center " style={{ fontFamily: 'Dancing Script'}}>
                            Vă așteptăm cu drag!
                        </h2>

                        
                    </div>

                )
            })}


<Slide1 />


        </ParallaxProvider>



    )
}

export default Home;