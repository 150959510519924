import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/Slide.css";

import { green } from "@mui/material/colors";
// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";



import img17 from '../images/c3.jpg';
import img18 from '../images/c4.jpg';
import img20 from '../images/c7.jpg';
import img22 from '../images/c9.jpg';
import img23 from '../images/c13.jpg';
import img24 from '../images/c16.jpg';
import img25 from '../images/c17.jpg';
import img28 from '../images/c24.jpg';




const Slide = () => {
    let data = [
       
        {
            id: 17,
            imgSrc: img17,
         
        },
        {
            id: 18,
            imgSrc: img18,
         
        },
       
        {
            id: 20,
            imgSrc: img20,
        
        },  
        {
            id: 22,
            imgSrc: img22,
         
        },
        {
            id: 23,
            imgSrc: img23,
         
        },
        {
            id: 24,
            imgSrc: img24,
           
        },
        {
            id: 25,
            imgSrc: img25,
        
        },
      
      
        {
            id: 28,
            imgSrc: img28,
         
        },
       
       
    ]
    return (
        <>
<section id="gallery" className="text-center container mt-5 mb-2">
               <h1 className=" pt-4 mb-2" ></h1> 
            <div className="slider mb-4 pb-4">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (<>
                       

                            <SwiperSlide>
                                

                                    <img src={item.imgSrc} />
                               
                                    
                              

                               

                            </SwiperSlide>


                    </>
                        )
                    })}

                </Swiper></div>
                </section>
        </>
    );
}

export default Slide;