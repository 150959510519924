
import { grey } from "@mui/material/colors";
import React from "react"

import FacebookIcon from '@mui/icons-material/Facebook';


const FooterB =()=> {
    return (

    <footer class="text-center text-white" >
  
  <div class="text-center p-3" style={{backgroundColor: grey[500]}}>
    © 1953-2023 Copyright: 
    <a className=" m-2  btn_logo" href="https://www.facebook.com/profile.php?id=100080147076243"><FacebookIcon />COCTE</a>
    
   
  </div>
    </footer>  

    )
}

export default FooterB;