import restaurant from "./images/r1.jpg";
import img_card from "./images/c3.jpg";
import imgheaderrestaurant from "./images/c3.jpg";
import imgheader from "./images/back-to-school-background-v.jpg";
import imgheaderm from "./images/back-to-school-background-v.jpg";
import img2 from "./images/c3.jpg";
import imgheadermiini from "./images/c3.jpg";
import imgheadermiinimb from "./images/c3.jpg";
import logo from './images/logou 2 cocte2.png'



const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Centrul Orășenesc de Creație ",
        mireasa: "Tehnică a Elevilor",
        data: "3 Decembrie 2023",
        logo: '',
        data_confirmare: "",
        savedata: "Vă invită!",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "constatinhincu@gmail.com", 
       tel: "+33755237369",
       phone: "tel:+33755237369",
       viber: "viber://chat?number=%2B33755237369",
       whatsapp: "https://wa.me/+33755237369",
       messenger: "https://www.messenger.com/t/",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Centrul Orășenesc de Creație Tehnică a Elevilor",
            data: "3 Decembrie 2023, duminică, ",
            ora: 'ora 11:00',
            adress: "str. Avram Iancu, 29, or. Chișinău",
            harta: "https://maps.app.goo.gl/d6T2dLWbbmKc9AWq6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10879.988550836917!2d28.8493799!3d47.0206612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d03a18696e9%3A0xfef1da469510d3f0!2sCOCTE!5e0!3m2!1sro!2s!4v1700050263825!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;