import React from "react"
import "../css/ImgInvitatie.css"

import inv from '../images/inv.jpg';


const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-5">

 
 
                      <img className="inv" src={inv} />



                        </div>



                        <a href="#geolocal" className="mb-4 btn btn-primary animate__animated animate__pulse animate__delay-4s animate__infinite">Vezi Harta</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;